<template>
    <div class="main">
        <!-- &lt;!&ndash;        百度地图bmap承接div&ndash;&gt; -->
        <div class="bm-view" id="allmap"></div>
        
    </div>
</template>

<script>

import {mapMutations} from "vuex";
import {getStore,setStore,getLocalStore} from "@/util/store";
//提前全局声明BMap，map，方便后续方法使用BMap.XX方法
let bmapPromise;
let BMap = {};
// let BMapLib = {};
let map = {};
export default {
    name: "index",
    data() {
        return {
            proname: getStore('thisProject').proName,
            //中心点图形
            proImg: require('../../assets/img/pro_map.png'),
          
            //中心点
            center: {
                lng: null,
                lat: null
            },
            //当前缩放比例
            ZoomNum: 10,
            //初始化缩放比例
            zoom: 10,
     
            searchResult: {},
            keySearchResult: {},
            keySearchResultShow: false,
            nowIcon: 'zb-bus',//周边-公交站
            //当前标点图形
            dotIcon: require('../../assets/img/map_icon.png'),
            //地图实例
            mapObj : '',
            isType: 'house'
        }
    },
    computed: {},
    created() {
        //获取地图中心、异步加载bmapjs文件
        this.getMapCenter();
        this.loadBmapJS();
        this.getReport(0,'','');
    },
    destroyed(){
        this.getReport(1,'','');
    },
    mounted() {
        //点击页面其他元素收起搜索栏
        document.addEventListener('click', (e) => {
            let thisClass = e.target.className
            if (thisClass !== 'searchResult' || thisClass !== 'searchAround') {
                this.keySearchResultShow = false
            }
        });
        setTimeout(() => {
            //初始化地图、默认首页查找地铁站
            this.initMap();
        }, 1000)
    },
    watch: {
        //监听ZoomNum（当前地图缩放级别），小于等于13 清除标志物
        ZoomNum() {
            if (this.ZoomNum > 13) {
                this.setHouseResult()
            } else {
                // map.clearOverlays() //bmap api 清除标志物
            }
        },
        searchResult() {
            if (this.searchResult == '') map.clearOverlays()
        },
        //监听当前搜索选择的标签，动态改变dotIcon图片路径
        // nowIcon() {
        //     this.dotIcon = require('../../assets/img/map_icon_' + this.nowIcon + '.png')
        // },
    },
    methods: {
        ...mapMutations(["reducePath", "addMenuList", "reduceMenuList"]),
        //返回上级页面
        back() {
            this.reduceMenuList();//删除历史菜单中最后一个元素（当前页的路由）
            this.reducePath();//删除导航最后一个路由
            var currentMenu = getStore("currentMenu");
            this.$router.push({path: currentMenu});
        },
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'mapAround',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //右侧快捷搜索功能块鼠标移入效果
        mouseOver(index) {
            // 操作dom 获取p标签改变其样式
            this.$refs.namehover[index].style.color = "#185BCE";
        },
        //右侧快捷搜索功能块鼠标移出效果
        mouseLeave(index) {
            this.$refs.namehover[index].style.color = '#323233';
        },
        //加载百度地图api
        loadBmapJS() {
            if (window.BMap) {
                return Promise.resolve();
            }
            if (bmapPromise) {
                return bmapPromise;
            }
            let script = document.createElement("script");
            script.type = "text/javascript";
            script.async = true;
            script.src = 'https://api.map.baidu.com/api?v=3.0&ak=jMnfAv2MlXEUmzzDjjGvlmXrpj0TP66a&callback=initMap'
         
            document.body.appendChild(script);
            bmapPromise = new Promise((resolve, reject) => {
                script.onload = () => {
                    resolve();
                    // this.loadBmapLibJS();
                };
                script.onerror = error => {
                    reject(error)
                }
            })

        },
        loadBmapLibJS() {
            let script = document.createElement("script");
            script.type = "text/javascript";
            script.src = "http://api.map.baidu.com/library/RichMarker/1.2/src/RichMarker_min.js";
            // script.onload = () => {
            //     this.initMap();
            // }
             bmapPromise = new Promise((resolve, reject) => {
                script.onload = () => {
                    resolve();
                    this.initMap();
                };
                script.onerror = error => {
                    reject(error)
                }
            })
            document.body.appendChild(script);
        },
        //初始化地图
        initMap() {

            BMap = window.BMap;
            map = new BMap.Map('allmap');
            this.mapObj = map;
            //初始化地图中心、层级
            map.centerAndZoom(new BMap.Point(this.center.lng, this.center.lat), this.zoom);
            //允许地图鼠标滚轮缩放层级
            map.enableScrollWheelZoom(true);
            //定义右上角百度地图工具栏 平移和缩放按钮
            var top_right_navigation = new BMap.NavigationControl({
                anchor: BMAP_ANCHOR_TOP_RIGHT,
                type: BMAP_NAVIGATION_CONTROL_SMALL
            });
            map.addControl(top_right_navigation);
            //百度全景地图
            var stCtrl = new BMap.PanoramaControl({
                anchor: BMAP_ANCHOR_BOTTOM_RIGHT,
                type: BMAP_NAVIGATION_CONTROL_SMALL
            });  
            stCtrl.setOffset(new BMap.Size(20, 20));  
            map.addControl(stCtrl);
            map.addControl(new BMap.MapTypeControl({
                mapTypes:[
                    BMAP_NORMAL_MAP,
                    BMAP_HYBRID_MAP
                ]}));	  
            // var navi3DCtrl = new BMap.NavigationControl3D();  // 添加3D控件
            // map.addControl(navi3DCtrl);
            //监听地图缩放，赋值到ZoomNum
            map.addEventListener("zoomend", (e) => {
                this.ZoomNum = e.target.getZoom()
            });
            // 创建点
            let myIcon = new BMap.Icon(this.proImg, new BMap.Size(28, 46));
            let mpoint = new BMap.Point(this.center.lng, this.center.lat);
            let marker = new BMap.Marker(mpoint, {icon: myIcon});
            map.addOverlay(marker);    //增加点
            marker.addEventListener("click", () => {

            });
            //中心点标签
            let cont = "<span class='markername' style='background-color:rgba(255,255,255,.8);border:1px solid #498AFF'>" + this.proname + "</span><span id='arrows'></span>";
            let label = new BMap.Label(cont, {offset: new BMap.Size(0, -15)});
            marker.setLabel(label);
            marker.disableMassClear(); //不被clearOverlay清除
            this.houseAxios();
        },
        //取得地图中心坐标
        getMapCenter() {
            this.center.lng = getStore('thisProject').longitude;
            this.center.lat = getStore('thisProject').latitude;
            // this.center.lng = '118.321';
            // this.center.lat = '40.153';
        },
   
        //给小区列表创建节点地图
        setHouseResult(icon) {
            map.clearOverlays()
            let result

            let pointList = [];
            let markerList = [];
            let myIcon;
            if (icon == false) {
                result = this.keySearchResult
                myIcon = new BMap.Icon(new BMap.Size(28, 45));
            } else {
                result = this.searchResult
                myIcon = new BMap.Icon(this.dotIcon, new BMap.Size(28, 46));
            }
            myIcon.setAnchor(new BMap.Size(28, 46));
            for (let i = 0; i < result.length; i++) {
                let index = i+1;
                let iconSrc = '../../assets/img/map_icon.png'
                let htm = '<div style="width: 28px;height: 45px;color:#fff;text-align:center;padding-top:8px;box-sizing:border-box;background: url('+this.dotIcon+');">'+index+'</div>'
                pointList[i] = new BMap.Point(result[i].longitude, result[i].latitude);
                markerList[i] = new BMap.Marker(pointList[i], {icon: myIcon});
                // markerList[i] = new BMapLib.RichMarker(htm, pointList[i],{"anchor":new BMap.Size(-25, -20)});
                markerList[i].id = result[i].id;
                map.addOverlay(markerList[i]);

                
                //点击标注显示悬浮窗
                let point = new BMap.Point(result[i].longitude, result[i].latitude);
                let content =
                    '<table style="width: 100%;border-collapse: collapse;">'+
                        '<tr><th style="background: #F6F6F6;border: 1px solid #EBEBEB;padding: 8px;text-align: right;padding-right: 11px;width: 90px;white-space: nowrap;">项目名称</th><td style="max-width: 269px;height: 1px;border: 1px solid #EBEBEB;padding: 8px;text-align: left;">' + result[i].projectName + '</td><th style="background: #F6F6F6;border: 1px solid #EBEBEB;padding: 8px;text-align: right;padding-right: 11px;width: 90px;white-space: nowrap;">总投资(万元)</th><td style="max-width: 269px;height: 1px;border: 1px solid #EBEBEB;padding: 8px;text-align: left;">' + result[i].amount + '</td></tr>'+
                        '<tr><th style="background: #F6F6F6;border: 1px solid #EBEBEB;padding: 8px;text-align: right;padding-right: 11px;width: 90px;white-space: nowrap;">项目属地</th><td style="max-width: 269px;height: 1px;border: 1px solid #EBEBEB;padding: 8px;text-align: left;">' + result[i].province+result[i].city+result[i].county + '</td><th style="background: #F6F6F6;border: 1px solid #EBEBEB;padding: 8px;text-align: right;padding-right: 11px;width: 90px;white-space: nowrap;">计划开工时间</th><td style="max-width: 269px;height: 1px;border: 1px solid #EBEBEB;padding: 8px;text-align: left;">' + result[i].startDate + '</td></tr>'+
                        '<tr><th style="background: #F6F6F6;border: 1px solid #EBEBEB;padding: 8px;text-align: right;padding-right: 11px;width: 90px;white-space: nowrap;">项目类别</th><td style="max-width: 269px;height: 1px;border: 1px solid #EBEBEB;padding: 8px;text-align: left;">' + result[i].projectType + '</td><th style="background: #F6F6F6;border: 1px solid #EBEBEB;padding: 8px;text-align: right;padding-right: 11px;width: 90px;white-space: nowrap;">计划竣工时间</th><td style="max-width: 269px;height: 1px;border: 1px solid #EBEBEB;padding: 8px;text-align: left;">' + result[i].endDate + '</td></tr>'+
                        '<tr><th style="background: #F6F6F6;border: 1px solid #EBEBEB;padding: 8px;text-align: right;padding-right: 11px;width: 90px;white-space: nowrap;">总面积(m²)</th><td style="max-width: 269px;height: 1px;border: 1px solid #EBEBEB;padding: 8px;text-align: left;">' + result[i].area + '</td></tr>'+
                    '</table>'
                let opt = {
                    width: 578,
                    height: 210,
                    message: result[i].address,
                    offset: new BMap.Size(-20, -20)
                }
                let infoWindow = new BMap.InfoWindow(content, opt)
                markerList[i].addEventListener("click", function(){   
                    // map.centerAndZoom(point, 16);
                    map.openInfoWindow(infoWindow, pointList[i]);
                });
            }

            

        },
        houseAxios(){
            this.axios.request({
                loading: true,
                method: 'post',
                url: '/dms/overview/allProject',
                 
                data: {
                    companyName: getStore('thisProject').taxpayerName,
                },
            }).then((res) => {
                if (res.data.data.length==0) {
                  
                } else {
                    this.searchResult = res.data.data;
                    this.setHouseResult();
                }
            })
        },
   
    }
}
</script>

<style scoped lang="less">
table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 8px;
    border: 1px solid #EBEBEB;
    white-space: nowrap;
    font-weight: 500;
    text-align: left;
    
}

th {
    background: #F6F6F6;
    padding-left: 11px;
    width: 90px;
    
}

td {
    height: 1px;
    white-space: normal;
    
}
.main {
    position: relative;
    width: 100%;
    height: 100%;

    .bm-view {
        position: relative;
        width: 100%;
        height: 100%;
    }

    /deep/ .BMapLabel {
        .markername {
            padding: 5px;
            display: block;
            height: 40px;
            line-height: 40px;
            width: 120px;
            text-align: center;
            border: 1px solid #cccccc;
            background-color: #fff;
            border-radius: 3px;
            font-weight: bold;
            font-size: 14px;
            box-shadow: 0 3px 14px rgba(0, 0, 100, .6);
            position: absolute;
            top: -41px;
            left: -47px;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        #arrows {
            background: url(../../assets/img/marksArrow.png) no-repeat center center;
            height: 11px !important;
            position: absolute;
            width: 30px;
            top: 10px;
            left: 0;
        }
    }

 

    .back {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 10px;
        left: 10px;
        background: url(../../assets/img/icon_back.png) no-repeat;
        cursor: pointer;
    }
   

    .BMap_noprint {
        margin: 10px 20px;
    }
    .btn-price{
        position: absolute;
        right: 0;
        top: 0;
        color: #185BCE;
    }
   
}

</style>
